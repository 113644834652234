/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
 import * as React from "react"
 import PropTypes from "prop-types"
 import Helmet from "react-helmet"
 import { injectIntl } from "gatsby-plugin-intl"
 import Header from "./Header3/Header"
 import Footer from "./Footer/Footer"
 
 const Layout = ({ children, intl }) => {
   return (
     <>
       <Helmet>
         <link href="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/css/flag-icon.min.css" rel="stylesheet"/>
         <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous"/>
         <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
       </Helmet>
       <Header siteTitle={intl.formatMessage({ id: "title" })} intl={intl}/>
       {children}
       <Footer/>
     </>
   )
 }
 
 Layout.propTypes = {
   children: PropTypes.node.isRequired,
 }
 
 export default injectIntl(Layout)