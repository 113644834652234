import React from "react"
import "./ShoptetVop.scss"

const ShoptetVop = () => {
    return (
        <section id="vop" className="page-section">
            <div className="container">
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <div className="info-panel">
                            <p>Platnost od: 31.12.2022</p>
                            <p>Poskytovatel doplňku:</p>
                            <p>coalios s.r.o.</p>
                            <p>Se sídlem Koliště 1912/13, Černá Pole, 602 00 Brno</p>
                            <p>IČ: 11998831</p>
                            <p>Zapsaná v obchodním rejstříku vedeném Krajským soudem v Brně, oddíl C, vložka 125751</p>
                            <p>Zastoupena Ing. Michalem Uhlířem</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                1 Definice pojmů
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            1.1 Shoptet - e-shopové řešení poskytované jako služba, které si můžete objednat na adrese <a href="https://www.shoptet.cz/">https://www.shoptet.cz/</a>. Shoptet není provozovatelem Doplňku a neodpovídá za Doplněk ani jeho obsah. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            1.2 Doplněk - jde o doplněk Napojení na ERP Helios iNuvio Lite uvedený na webové stránce <a href="https://doplnky.shoptet.cz/helios-inuvio-erp">https://doplnky.shoptet.cz/helios-inuvio-erp</a>. Slouží k výměně dat mezi Shoptetem a informačním systémem Helios iNuvio pomocí vybraných endpointů a objektů rozhraní Shoptet API - viz
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            1.3 Coalios - poskytovatel Doplňku uvedený výše. Coalios poskytuje Zákazníkovi licenci k Doplňku. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            1.4 Zákazník - to jste Vy, uživatel Doplňku, náš zákazník, který je identifikovaný jménem, příjmením, případně názvem, identifikačním číslem a sídlem v administraci Shoptetu.
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            1.5 Helios Data Manager - aplikace instalovaná na serveru Zákazníka, která zajišťuje komunikaci mezi Doplňkem a ERP Helios iNuvio. Není součástí dodávky Doplňku a Zákazník si ji objednává zvlášť přímo u Coaliosu.
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            1.6 Instalace Helios Data Manager - provádí Coalios po objednání Zákazníkem na serveru Zákazníka.  
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            1.7 Instalace Doplňku - objednávku a instalaci provádí Zákazník na adrese <a href="https://doplnky.shoptet.cz/helios-inuvio-erp">https://doplnky.shoptet.cz/helios-inuvio-erp</a>, a to až poté, co objedná službu Helios Data Manager a její implementaci. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            1.8 Objednávka - závazná objednávka licencí a služeb přes webovou stránku <a href="https://doplnky.shoptet.cz/helios-inuvio-erp">https://doplnky.shoptet.cz/helios-inuvio-erp</a>. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            1.9 Smlouva - závazná Objednávka akceptovaná Coalios, bez akceptace Coalios není uzavřena Smlouva. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            1.10 Zkušební doba - za účelem testování Coalios umožní Zákazníkovi bezplatný přístup k Doplňku, a to maximálně po dobu 30ti dní. Tato doba je určená pro otestování a dokončení implementace. Podmínkou bezplatného přístupu je objednání instalace a služby Helios Data Manager.
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            1.11 Ceník licence a služeb - je uveden v ceníku na webové stránce <a href="https://doplnky.shoptet.cz/helios-inuvio-erp">https://doplnky.shoptet.cz/helios-inuvio-erp</a>.
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                2 Uzavření smlouvy 
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            2.1 Objednávkou Zákazníka vzniká smluvní vztah mezi Coalios a Zákazníkem.  
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            2.2 Objednávku Zákazník dokončí až poté, co objedná Helios Data Manager u Coalios.  
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            2.3 Zákazník při objednávce prostřednictvím objednávkového formuláře na webové stránce <a href="https://doplnky.shoptet.cz/helios-inuvio-erp">https://doplnky.shoptet.cz/helios-inuvio-erp</a> prohlašuje, že se seznámil s funkčností objednávaného Doplňku, Helios Data Manager a jejich vzájemnou funkčností. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            2.4 Objednávkou potvrzuje Zákazník, že je s těmito Obchodními podmínkami srozuměn, vyjadřuje svůj souhlas s jejich zněním, a že je v celém rozsahu akceptuje. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            2.5 Pokud Zákazník neobjedná Helios Data Manager, ale objedná jen Doplněk, nebude Doplněk plnit účel a Zákazník s tímto je obeznámen a výslovně s touto skutečností souhlasí.  
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            2.6 Údaje uvedené Zákazníkem v Shoptetu jsou Coalios pro účely objednání a fakturace Doplňku považovány za správné. 
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                3 Provoz a užívání Doplňku, licenční ujednání 
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            3.1 Předmětem Smlouvy mezi Coalios a Zákazníkem je závazek Coalios poskytnout Zákazníkovi oprávnění k dočasnému a nevýhradnímu užívání softwarových funkcionalit Doplňku a závazek Zákazníka zaplatit za toto oprávnění Coalios dle Ceníku.  
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            3.2 Doplněk je určen výhradně k propojení informačního systému Helios iNuvio pomocí Helios Data Manager. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            3.3 Doplněk požívá ochrany ve smyslu jednotlivých ustanovení Autorského zákona.  
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            3.4 Zákazník není oprávněn dát Doplněk do podnájmu kterékoli třetí osobě. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            3.5 Zákazník je povinen zajistit dodržování Obchodních podmínek a je si vědom toho, že v případě jejich porušení smí Coalios pozastavit funkčnost Doplňku, a to bez jakékoliv finanční či nefinanční náhrady Zákazníkovi. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            3.6 Coalios se zavazuje po dobu trvání smluvního vztahu Zákazníkovi zpřístupnit licence v rozsahu zaplacených služeb podle platného ceníku.  
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            3.7 Coalios se zavazuje zajistit garantovanou úroveň dostupnosti Doplňku (tedy možnosti jej bez jakýchkoliv obtíží, poruch či závad používat) v rozsahu nejméně 99 % veškerého času v kalendářním měsíci 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            3.8 Zákazník souhlasí s tím, že Shoptet nemá povinnost poskytovat jakékoliv služby podpory nebo údržby a nenese odpovědnost za vypořádání nároků Zákazníka nebo jakékoliv třetí strany ve vztahu ke Coalios a  námi provozovanými Doplňky, ať už vyplývají z odpovědnosti za vady, způsobené škody, porušení právních předpisů, zejména v oblasti zpracování osobních údajů nebo jiných práv. 
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                4 Cena a platební podmínky 
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            4.1 Úhrada za licence a služby Helios Data Manager se provádí na základě vystavené faktury Coalios a je nevratná. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            4.2 Úhrada za licence a služby Doplňku je fakturována provozovatelem Shoptet a je nevratná. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            4.3 Cena poplatků za licence a služby Helios Data Manager je účtována vždy minimálně za 12 měsíců dopředu. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            4.4 Zákazník souhlasí k zasílání daňových dokladů (faktur) výlučně v elektronické podobě, elektronickou cestou ve smyslu příslušných ustanovení zákona č. 235/2004 Sb. o dani z přidané hodnoty. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            4.5 V případě prodlení Zákazníka s úhradou licencí nebo služeb má Coalios právo Zákazníkovi ukončit funkčnost Doplňku poskytovaného dle Objednávky. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            4.6 Pokud Zákazník neuhradí sjednané ceny specifikované v odstavcích 4.1. a 4.2. výše do jednoho měsíce ode dne splatnosti uvedeného na příslušných fakturách, je Coalios oprávněn od smlouvy odstoupit s takovým důsledkem, že Zákazník okamžikem takového odstoupení ztrácí poskytnuté oprávnění k výkonu práv k Doplňku a/nebo Helios Data Manager. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            4.7 V případě, že se Zákazník dostane do prodlení s úhradou jakéhokoli finančního plnění vyplývajícího z této smlouvy, bere Zákazník na vědomí a současně souhlasí s tím, že Coalios je oprávněn neprovádět žádné úkony spojené se systémovou podporou či dalšími službami ve smyslu této smlouvy až do okamžiku, kdy Zákazník splní veškeré finanční závazky vůči Coalios. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            4.8 V případě, že je Zákazníkovi funkcionalita poskytnuta v průběhu měsíce, fakturuje se Zákazníkovi celý započatý měsíc. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            4.9 Coalios je oprávněn jednou v průběhu každého kalendářního roku jednostranně upravit ceny uvedené ve Smlouvě (Smlouvách) v rozsahu míry inflace vyjádřené přírůstkem průměrného ročního indexu spotřebitelských cen v České republice za předchozí kalendářní rok, zveřejněné Českým statistickým úřadem. Ceny upravené Coalios podle tohoto článku budou platné počínaje měsícem, ve kterém byla jejich úprava elektronicky oznámena Zákazníkovi. Ceny podle tohoto odstavce lze poprvé uplatnit v průběhu kalendářního roku následujícího po kalendářním roce, ve kterém smlouva nabyla účinnost. 
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                5 Podpora a údržba 
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            5.1 Coalios se zavazuje zajistit, že Doplněk bude dostupný (tedy bude řádně a bez jakýchkoliv obtíží, poruch či závad) a bude možné jej používat k účelu uvedenému na jeho Profilu v rozsahu nejméně 99 % veškerého času v kalendářním měsíci, s výjimkou standardních výluk a možnosti provádění odstávek v obvyklém rozsahu. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            5.2 Coalios se zavazuje se poskytovat uživatelům Doplňku technickou podporu prostřednictvím telefonického a e-mailového kontaktu, který se nachází v profilu Doplňku. Bezplatnou technickou podporou se rozumí pomoc s řešením vad Doplňku, ostatní dotazy nemající povahu reklamace jsou konzultace nebo školení a mohou být zpoplatněny dle ceníku Coalios.  
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            5.3 V případě dotazu uživatele Doplňku Coalios zajistí v pracovních dnech první odpověď do 24 hodin (pro vyloučení pochybností, pokud bude dotaz položen v pracovní den, po kterém následuje den pracovního klidu, přestává lhůta k první odpovědi běžet ve 23:59 hod. daného pracovního dne a znovu začíná běžet až v 0:01 hod. prvního následujícího pracovního dne). 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            5.4 Coalios zajišťuje průběžnou správu a aktualizaci Doplňku. 
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                6 Reklamace a odpovědnost
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            6.1 Za jakékoliv vady Doplňku, za veškeré poskytnuté záruky a případnou škodu způsobenou Doplňkem odpovídá výhradně Coalios  
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            6.2 Coalios garantuje funkčnost Doplňku odpovídající dodané dokumentaci. V případě zjištění vad, je Zákazník oprávněn uplatnit reklamaci. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            6.3 Zákazník posílá reklamace výlučně na e-mail <a href="mailto:info@coalios.cz">info@coalios.cz</a> 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            6.4 Coalios reklamaci řeší bez zbytečných odkladů, v krajním případě bude reklamace vyřízena do 30ti kalendářních dnů ode dne přijetí reklamace. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            6.5 Pro oprávněné uplatnění záručních podmínek smluvní strany sjednávají, že Zákazník je povinen:
                            <p className="offset-point">
                                6.5.1 bez výjimek dodržovat pokyny uvedené v dokumentaci prodávaného programového vybavení a ve výše citovaných obchodních podmínkách 
                            </p>
                            <p className="offset-point">
                                6.5.2 zajistit stálý přístup k serveru s ERP Helios iNuvio 
                            </p>
                            <p className="offset-point">
                                6.5.3 zajistit obsluhu pouze vyškolenými pracovníky 
                            </p>
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            6.6 Smluvní strany činí nesporným, že Coalios neručí za bezpečnost dat a neodpovídá za závady informačního systému HELIOS iNuvio, Doplňku nebo Shoptetu, které prokazatelně nevzniknou jeho zásahem nebo které vzniknou v důsledku neposkytnutí informací či součinnosti ze strany Zákazníka nutných pro řádné předání a uvedení do provozu. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            6.7 Smluvní strany se zavazují, že veškeré informace získané v rámci obchodního vztahu vyplývajícího z této smlouvy budou považovány za důvěrné a nebudou z jejich strany zneužity ani jinak šířeny. 
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                7 Závěrečná ustanovení 
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            7.1 Zákazník se zavazuje veškerou korespondenci vzájemně doručovat prostřednictvím elektronické pošty, a to na emailové adresy, které si potvrdí Zákazník s Coalios při objednání Helios Data Manager.  
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            7.2 Všeobecné obchodní podmínky, které jsou uveřejněny na Internetové stránce, si Provozovatel vyhrazuje kdykoliv změnit. Pro Uživatele jsou závazné vždy platné a účinné VOP ve znění uveřejněném na Internetové stránce. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            7.3 Obchodní podmínky jsou vyhotoveny v českém jazyce a případné spory se řeší v českém jazyce. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            7.4 Provozovatel a Uživatel stanovili pravomoc soudů České republiky. 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            7.5 Je-li některé ustanovení Obchodních podmínek neplatné nebo neúčinné, nebo se takovým stane, namísto neplatných ustanovení nastoupí ustanovení, jehož smysl se neplatnému ustanovení co nejvíce přibližuje. Neplatností nebo neúčinností jednoho ustanovení není dotknutá platnost ostatních ustanovení 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            7.6 Tyto Všeobecné obchodní podmínky jsou platné a účinné od 31. prosince 2022 
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ShoptetVop