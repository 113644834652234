import React from 'react'
import logo from '../../images/logo/logo_inverse.png'
import { Link } from "gatsby-plugin-intl"
import "./Header.scss"

const Header = () => {
    return (
        <header>
            <nav className="navbar navbar-expand-xl fixed-top nav-black" id="mainNav">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} alt="" />
                    </Link>
                </div>
            </nav>
        </header>
    )
}

export default Header